import PropTypes from 'prop-types';
import ImageWithDefault from '../shared/ImageWithDefault';
import css from './team-logos.module.scss';

const TeamLogos = ({ teamsInfo }) => (
  <div className={css.container}>
    <ImageWithDefault
      dataTestId="teamLogo1"
      className={css.imgThumbnail}
      src={teamsInfo.firstTeamLogoUrl}
      alt="team logo 1"
    />
    <div className={css.separator}>
      <p className={css.vs}>{teamsInfo.teamsSeparator}</p>
    </div>
    <ImageWithDefault
      dataTestId="teamLogo2"
      className={css.imgThumbnail}
      src={teamsInfo.secondTeamLogoUrl}
      alt="team logo 2"
    />
  </div>
);

TeamLogos.propTypes = {
  teamsInfo: PropTypes.object.isRequired,
};

export default TeamLogos;
