import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { DISCORD_PLAYBACK_DISABLED_TEXT } from 'src/common/components/player/actionDrawer/DiscordControls/constants';
import withSyncedPlayerBehavior from 'src/common/decorators/withSyncedPlayerBehavior';
import {
  selectDiscordState,
  selectIsDiscord,
  selectIsMobile,
} from 'src/common/selectors/app';
import cssVariables from 'src/common/styles/variables';
import { playerStatuses } from '../../constants/playerStatuses';
import useMobilePopout from '../../hooks/useMobilePopout';
import {
  selectNowPlayingRejectReasonKey,
  selectTunedGuideId,
} from '../../selectors/player';
import { isMedium } from '../../utils/breakpoints';
import { getCssStyle } from '../../utils/getCssStyle';
import gtagTrack from '../../utils/gtagTrack';
import { playerStatusToSvgIconMap } from '../../utils/playerStatusToIconMap';
import GuideItemLink from '../containerItems/shared/GuideItemLink';
import MessagePopoverWithTail from '../messagePopoverWithTail/MessagePopoverWithTail';
import CircularProgress from '../shared/CircularProgress';
import PopOutButton from '../shared/button/PopOutButton';
import css from './playButton.module.scss';

const LARGE_BUTTON = 72;
const MEDIUM_BUTTON = 56;
const SMALL_BUTTON = 48;

export const PlayButton = ({
  canScrub,
  bannerImage,
  syncedPlayerStatus,
  handlePlayButtonClick,
  externalHtmlPlayerUrl,
  guideItem = {},
  breakpoint,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const isDiscord = useSelector(selectIsDiscord);
  const isMobile = useSelector(selectIsMobile);
  const discordState = useSelector(selectDiscordState);
  const tunedGuideId = useSelector(selectTunedGuideId);
  const { canShowPopover, handleTargetClick } = useMobilePopout(isMobile, 3000);
  const nowPlayingRejectReasonKey = useSelector(
    selectNowPlayingRejectReasonKey,
  );
  const isPopoverOpen = Boolean(
    isDiscord &&
      (!discordState?.canControlPlayback ||
        (nowPlayingRejectReasonKey && guideItem.guideId === tunedGuideId)) &&
      ((isMobile && canShowPopover) || (!isMobile && isHovering)),
  );

  const getButtonSize = () => {
    if (isDiscord && !!bannerImage) {
      return MEDIUM_BUTTON;
    }

    if (!isMedium(breakpoint)) {
      return LARGE_BUTTON;
    }

    return SMALL_BUTTON;
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const buttonSize = getButtonSize();

  if (syncedPlayerStatus === playerStatuses.connecting) {
    return (
      <div className={css.playButton}>
        <CircularProgress
          data-testid="profilePlayerLoadingSpinner"
          size={buttonSize}
          color={getCssStyle('--text-color')}
          thickness={2.5}
        />
      </div>
    );
  }

  if (syncedPlayerStatus === playerStatuses.popout) {
    return (
      <PopOutButton
        externalHtmlPlayerUrl={externalHtmlPlayerUrl}
        width={`${buttonSize}px`}
        height={`${buttonSize}px`}
        dataTestId="profilePopOutButton"
      />
    );
  }

  const PlayButtonStateRepresentation =
    playerStatusToSvgIconMap[syncedPlayerStatus]?.(canScrub);

  return PlayButtonStateRepresentation ? (
    <GuideItemLink
      guideItem={guideItem}
      onClick={(...args) => {
        gtagTrack.profilePlayButtonClick();
        handlePlayButtonClick(...args);
      }}
    >
      <div
        className={css.playButtonWrapper}
        onClick={handleTargetClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <MessagePopoverWithTail
          message={
            guideItem.guideId === tunedGuideId
              ? nowPlayingRejectReasonKey || DISCORD_PLAYBACK_DISABLED_TEXT
              : DISCORD_PLAYBACK_DISABLED_TEXT
          }
          isOpen={isPopoverOpen}
        />
        <PlayButtonStateRepresentation
          id="profilePlayerActionButton"
          dataTestId="profilePlayerActionButton"
          width={`${buttonSize}px`}
          height={`${buttonSize}px`}
          fillButton={isDiscord ? cssVariables['--secondary-color-5'] : ''}
          fillButtonBackground={isDiscord ? cssVariables['--white'] : ''}
        />
      </div>
    </GuideItemLink>
  ) : null;
};

PlayButton.propTypes = {
  syncedPlayerStatus: PropTypes.string.isRequired,
  handlePlayButtonClick: PropTypes.func.isRequired,
  bannerImage: PropTypes.string,
  guideItem: PropTypes.object.isRequired,
  breakpoint: PropTypes.number.isRequired,
  canScrub: PropTypes.bool,
  externalHtmlPlayerUrl: PropTypes.string,
};

export default withSyncedPlayerBehavior(PlayButton);
