import PropTypes from 'prop-types';
import { DONATE } from '../../constants/localizations/profile';
import Text from '../Text';
import OutlinedPillButton from '../shared/button/OutlinedPillButton';
import css from './donateButton.module.scss';

const DonateButton = ({ text, url }) => (
  <a
    className={css.link}
    target="_blank"
    href={url}
    rel="noopener"
    data-testid="profileDonate"
  >
    <OutlinedPillButton
      id="donateButton"
      className={css.donateButton}
      label={text || <Text name={DONATE} />}
    />
  </a>
);

DonateButton.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string,
};

export default DonateButton;
