import { useEffect, useReducer } from 'react';
import config from 'src/common/config';
import { imageDomainToProxyPathMap } from '../components/discord';
import { getPalette } from '../components/profile/utils/getPalette';
import { isInDiscordIFrame } from '../utils/discord';

const discordProxyHostname = new URL(config.discordOriginsMap.discordProxy)
  .hostname;
const initialState = {
  loading: true,
  data: {},
  error: undefined,
};

const GET_PALETTE = 'getPalette';
const RESOLVE_PALETTE = 'resolvePalette';
const REJECT_PALETTE = 'rejectPalette';

function reducer(state, action) {
  switch (action.type) {
    case GET_PALETTE:
      return initialState;
    case RESOLVE_PALETTE:
      return { ...state, data: action.payload, loading: false };
    case REJECT_PALETTE:
      return { ...state, error: action.payload, loading: false };
    default:
      return initialState;
  }
}

function maybeTransformSrc(src) {
  if (!isInDiscordIFrame()) {
    return src;
  }

  const srcUrl = new URL(src);
  const proxyPath = imageDomainToProxyPathMap[srcUrl.hostname];

  if (proxyPath) {
    return src.replace(
      srcUrl.origin,
      `https://${discordProxyHostname}${proxyPath}`,
    );
  }

  return src;
}

export function usePalette(src) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!src) {
      return;
    }

    dispatch({ type: GET_PALETTE });

    async function fetchPalette() {
      try {
        const palette = await getPalette(maybeTransformSrc(src));
        dispatch({ type: RESOLVE_PALETTE, payload: palette });
      } catch (ex) {
        dispatch({ type: REJECT_PALETTE, payload: ex });
      }
    }

    fetchPalette();
  }, [src]);

  return state;
}
