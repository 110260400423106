import get from 'lodash/get';
import { gameStatus, types } from '../guideItemTypes';

export default function canEmbed(guideItem) {
  const eventStatus = get(guideItem, 'metadata.properties.program.eventStatus');
  const canEmbedGuideItem = get(guideItem, 'actions.embed.canEmbed');
  const isFutureEvent = eventStatus === gameStatus.future;
  const isUserArtistOrAlbum = [types.user, types.artist, types.album].includes(
    guideItem.type,
  );

  return !!(!isUserArtistOrAlbum && !isFutureEvent && canEmbedGuideItem);
}
