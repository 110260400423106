import classNames from 'clsx';
import PropTypes from 'prop-types';
import { Component } from 'react';
import ProfileLogo from './ProfileLogo';
import css from './banner.module.scss';

class Banner extends Component {
  static propTypes = {
    src: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    logoImage: PropTypes.string,
    heroHeader: PropTypes.bool,
    showLogoImage: PropTypes.bool,
    className: PropTypes.string,
    hasPremiumBadge: PropTypes.bool,
    hasBoostBadge: PropTypes.bool,
    isDiscord: PropTypes.bool,
    guideItem: PropTypes.object,
  };

  state = {
    imageLoadFailed: false,
  };

  onImageLoadError = () => {
    this.setState({ imageLoadFailed: true });
  };

  render() {
    if (this.state.imageLoadFailed) {
      return null;
    }

    const {
      src,
      showLogoImage,
      logoImage,
      heroHeader,
      title,
      hasPremiumBadge,
      hasBoostBadge,
      className,
      isDiscord,
      guideItem,
    } = this.props;

    return (
      <section className={className}>
        <div
          data-testid="profileBannerWrapper"
          className={classNames(css.bannerWrapper, {
            [css.hasHeroHeader]: !!heroHeader || isDiscord,
          })}
        >
          <img
            src={src}
            alt={title}
            className={css.banner}
            data-testid="profileBanner"
            onError={this.onImageLoadError}
          />
        </div>
        {showLogoImage && (
          <ProfileLogo
            containerClassName={css.mobileWebProfileLogoContainer}
            imageWrapperClassName={css.mobileWebProfileLogoImageWrapper}
            key={logoImage}
            image={logoImage}
            title={title}
            hasPremiumBadge={hasPremiumBadge}
            hasBoostBadge={hasBoostBadge}
            guideItem={guideItem}
          />
        )}
      </section>
    );
  }
}

export default Banner;
