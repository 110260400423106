import get from 'lodash/get';
import fullUrl from 'src/common/utils/fullUrl';

const relAlternate = 'alternate';
const jsonType = 'application/json+oembed';
const xmlType = 'text/xml+oembed';
const jsonFormat = 'json';
const xmlFormat = 'xml';

function getProfilesUrl(guideId) {
  // no edgecast rewrite rule exists for "profiles"
  // see retro ProfilesController for implementation
  return fullUrl(`/profiles/${guideId}/`);
}

function getOEmbedUrl(guideId, format) {
  const urlParam = encodeURIComponent(getProfilesUrl(guideId));
  // no edgecast rewrite rule exists for "oembed"
  // see retro OEmbedController for implementation
  return fullUrl(`/oembed/?url=${urlParam}&format=${format}`);
}

export default function createOEmbedLinks(guideId, title, embedAction) {
  if (get(embedAction, 'canEmbed')) {
    return [
      {
        rel: relAlternate,
        type: jsonType,
        title,
        href: getOEmbedUrl(guideId, jsonFormat),
      },
      {
        rel: relAlternate,
        type: xmlType,
        title,
        href: getOEmbedUrl(guideId, xmlFormat),
      },
    ];
  }

  return [];
}
