import map from 'lodash/map';
import PropTypes from 'prop-types';

import commonCss from '../../styles/common.module.scss';
import { getDestInfoUrlObjWithLogger } from '../../utils/guideItem/getGuideItemPathname';
import ExternalLink from '../shared/link/ExternalLink';
import GuideItemLinkWithLogger from '../shared/link/GuideItemLinkWithLogger';
import css from './more-information.module.scss';

const actionLinkPropTypes = {
  children: PropTypes.node.isRequired,
  linkAction: PropTypes.object.isRequired,
  className: PropTypes.string,
};

const LinkActionLink = ({ children, linkAction, className }) => (
  <ExternalLink href={linkAction.webUrl} className={className}>
    {children}
  </ExternalLink>
);

const BrowseActionLink = ({ children, linkAction, className }) => (
  <GuideItemLinkWithLogger
    guideItemLink={getDestInfoUrlObjWithLogger(linkAction.destinationInfo)}
    className={className}
  >
    {children}
  </GuideItemLinkWithLogger>
);

LinkActionLink.propTypes = actionLinkPropTypes;
BrowseActionLink.propTypes = actionLinkPropTypes;

const actionLinkComponentMap = {
  browse: BrowseActionLink,
  link: LinkActionLink,
};

const MoreInformation = ({ guideItem }) => (
  <div data-testid="moreInfoRows" className={css.moreInfoRows}>
    {guideItem.infoAttributes.map((item, i) => (
      <div key={i} data-testid={`attribute-${i}`}>
        <p data-testid={`name-${i}`} className={css.name}>
          {item.name}
        </p>
        {item.children ? (
          item.children.map((linkItem, j) =>
            map(linkItem.actions, (action, actionName) => {
              const ActionLink = actionLinkComponentMap[actionName];

              if (ActionLink) {
                return (
                  <ActionLink
                    key={`link-${i}-${j}-${actionName}`}
                    className={commonCss.inkLink}
                    linkAction={action}
                  >
                    <p data-testid={`text-${i}-${j}`} className={css.text}>
                      {linkItem.text}
                    </p>
                  </ActionLink>
                );
              }
              // fallback in case some new actionName is introduced without us knowing
              return (
                <p data-testid={`text-${i}-${j}`} className={css.text}>
                  {linkItem.text}
                </p>
              );
            }),
          )
        ) : (
          <p data-testid={`text-${i}`} className={css.text}>
            {item.text}
          </p>
        )}
      </div>
    ))}
  </div>
);

MoreInformation.propTypes = {
  guideItem: PropTypes.object.isRequired,
};

export default MoreInformation;
