export default function createOgMeta(guideItem, url, title, description) {
  const {
    actions: {
      share: { shareText, logoUrl, logoContentType } = {},
    } = {},
    title: guideTitle,
    rootGenre,
    description: guideDescription,
    bannerImage,
    image,
  } = guideItem;

  const type = rootGenre === 'music' ? 'music.radio_station' : 'website';

  const propsArray = [
    { property: 'og:title', content: title || shareText || guideTitle },
    { property: 'og:description', content: description || guideDescription },
    { property: 'og:url', content: url },
    { property: 'og:type', content: type },
    { property: 'og:site_name', content: 'TuneIn' },
  ];

  if (logoUrl && logoContentType) {
    propsArray.push({
      property: 'og:image:type',
      content: logoContentType,
    });
  }

  const ogImage = logoUrl || bannerImage || image || '';

  if (!ogImage) return propsArray;

  propsArray.push({
    property: 'og:image',
    content: ogImage,
  });

  if (ogImage.startsWith('https')) {
    propsArray.push({
      property: 'og:image:secure_url',
      content: ogImage,
    });
  }

  return propsArray;
}
