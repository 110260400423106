import { Accordion } from '@tunein/web-ui-components';
import classNames from 'clsx';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import DisplayAd from 'src/common/components/ads/DisplayAd';
import { FREESTAR_ENABLED } from 'src/common/constants/experiments/ads';
import useExperiment from 'src/common/hooks/useExperiment';
import cssVars from 'src/common/styles/variables';
import {
  getDisplayAdAttributes,
  gptSlotNames,
} from 'src/common/utils/ads/constants';
import { isMedium, isXLarge, isXXLarge } from 'src/common/utils/breakpoints';
import { sharingFromProfile } from '../../actions/share';
import {
  LESS,
  LESS_INFORMATION,
  MORE,
  MORE_INFORMATION,
} from '../../constants/localizations/profile';
import { usePalette } from '../../hooks/usePalette';
import { selectDiscordState, selectIsDiscord } from '../../selectors/app';
import {
  selectNowPlayingRejectReasonKey,
  selectTunedGuideId,
} from '../../selectors/player';
import { programTypes } from '../../utils/guideItemTypes';
import CollapsibleLabel from '../shared/CollapsibleLabel';
import Banner from './Banner';
import MoreInformation from './MoreInformation';
import PageTitles from './PageTitles';
import PlayButton from './PlayButton';
import ProfileLogo from './ProfileLogo';
import SocialControls from './SocialControls';
import TeamLogos from './TeamLogos';
import css from './header.module.scss';

export function Header({
  guideItem,
  actions,
  profileActions,
  bannerImage,
  heroHeader,
  image,
  title,
  description,
  affiliate,
  genres,
  subtitle,
  teamsInfo,
  contentInfo,
  breakpoint,
  routeProps,
  canShowAds,
  canExpand,
  shouldShowMore,
  handleMoreClick,
  isDiscord,
  discordState,
}) {
  const shouldShowSocialControls =
    profileActions.follow && profileActions.share;
  const isMediumBreakpoint = isMedium(breakpoint);
  const canFollow = profileActions?.follow?.canFollow;
  const canShowDisplayAd =
    canShowAds &&
    ((isMediumBreakpoint && !isXLarge(breakpoint)) || isXXLarge(breakpoint));
  const hasPremiumBadge = !!(
    contentInfo?.isPremium && contentInfo?.type === programTypes.audiobook
  );
  const hasBoostBadge = !!contentInfo?.isBoostStation;
  const nowPlayingRejectReasonKey = useSelector(
    selectNowPlayingRejectReasonKey,
  );
  const tunedGuideId = useSelector(selectTunedGuideId);
  const isFreestarEnabled = useExperiment(FREESTAR_ENABLED);

  /* Big play button should be hidden for profiles that don't have a stream(canPlay=false)
  or no guideId for the tune call or podcast profile and experiment is off.
   */
  const hidePlayButton =
    !profileActions.play?.canPlay || !profileActions.play?.guideId;

  const {
    data: paletteData,
    loading: paletteLoading,
    error: paletteError,
  } = usePalette(image);

  const gradientBottom = isDiscord
    ? '--dark-grey'
    : '--content-area-background-color-hex';
  const isColorPaletteReady = !paletteLoading && paletteData?.vibrant;
  const isDiscordWithBanner = isDiscord && !!bannerImage;
  const containerStyles = {};

  if (isColorPaletteReady && !teamsInfo && !isDiscordWithBanner) {
    containerStyles.background = `linear-gradient(180deg, ${paletteData.vibrant}CC, ${cssVars[gradientBottom]})`;
  }

  const renderLogo = () => {
    if (teamsInfo) {
      return <TeamLogos teamsInfo={teamsInfo} />;
    }

    if (!isDiscordWithBanner && (isMediumBreakpoint || !bannerImage)) {
      return (
        <ProfileLogo
          key={image}
          image={image}
          hasPremiumBadge={hasPremiumBadge}
          hasBoostBadge={hasBoostBadge}
          title={title}
          guideItem={guideItem}
        />
      );
    }

    return null;
  };

  const renderCollapsibleLabel = () => {
    const collapsibleLabelProps =
      !isDiscordWithBanner && isMediumBreakpoint
        ? {
            className: css.moreInfoButton,
            caretClassName: css.moreInfoCaret,
            moreText: MORE_INFORMATION,
            lessText: LESS_INFORMATION,
          }
        : {
            className: classNames(css.moreInfoButton, css.mobileMoreInfoButton),
            caretClassName: css.mobileMoreInfoCaret,
            moreText: MORE,
            lessText: LESS,
          };

    return (
      <div
        className={classNames(css.collapsibleLabelContainer, {
          [css.expandedInfo]: shouldShowMore,
          [css.isDiscord]: isDiscord,
          [css.withoutPlayButton]: hidePlayButton,
          [css.isDiscordWithBanner]: isDiscordWithBanner,
        })}
      >
        <CollapsibleLabel
          shouldShowMore={shouldShowMore}
          onMoreInfoClick={handleMoreClick}
          {...collapsibleLabelProps}
        />
      </div>
    );
  };

  const accordionSection = (
    <section className={classNames('row', css.collapsibleLabelSection)}>
      <div className="col-sm-12">
        {canExpand && (
          <>
            {!isDiscordWithBanner &&
              isMediumBreakpoint &&
              renderCollapsibleLabel()}
            <Accordion
              data-testid="accordionComponent"
              isExpanded={shouldShowMore}
            >
              <div
                data-testid="moreInfoContent"
                className={classNames(css.moreInfoContent, {
                  [css.isDiscord]: isDiscord,
                })}
              >
                <MoreInformation guideItem={guideItem} />
              </div>
            </Accordion>
          </>
        )}
        {!isDiscordWithBanner && isMediumBreakpoint && (
          <div
            className={classNames(css.dividerContainer, {
              [css.noCollapsibleLabelContainer]: !canExpand,
              [css.isDiscord]: isDiscord,
            })}
          >
            <hr />
          </div>
        )}
      </div>
    </section>
  );

  return (
    <>
      <div
        style={containerStyles}
        className={classNames(css.container, {
          [css.headerContainerDefault]:
            !isDiscordWithBanner &&
            (teamsInfo || (!paletteLoading && paletteError)),
          [css.paddingTop]: !bannerImage && !canShowDisplayAd && !isDiscord,
        })}
      >
        {bannerImage ? (
          <Banner
            src={bannerImage}
            hasPremiumBadge={hasPremiumBadge}
            hasBoostBadge={hasBoostBadge}
            logoImage={image}
            showLogoImage={
              !!bannerImage &&
              (isDiscord || (!teamsInfo && !isMediumBreakpoint))
            }
            heroHeader={heroHeader}
            title={title}
            isDiscord={isDiscord}
            className={classNames(css.banner, {
              [css.hasHeroHeader]: !!heroHeader || isDiscordWithBanner,
              [css.bannerMaxWidth]: isDiscordWithBanner,
            })}
            guideItem={guideItem}
          />
        ) : (
          canShowDisplayAd && (
            <DisplayAd
              style={{ paddingBottom: '45px' }}
              guideId={routeProps?.guideContext?.guideId}
              matchUrl={routeProps.matchUrl}
              isProfile
              {...getDisplayAdAttributes(
                gptSlotNames.profile_top,
                isFreestarEnabled,
              )}
            />
          )
        )}

        <section className={classNames('row', css.headerSection)}>
          <div
            className={classNames('col-xs-12', css.contentContainer, {
              [css.hasBannerImage]: !!bannerImage,
              [css.discord]: isDiscord,
            })}
          >
            {renderLogo()}
            <div
              className={classNames(css.titleActionButtonsContainer, {
                [css.isDiscord]: isDiscord,
                [css.isDiscordWithBanner]: isDiscordWithBanner,
              })}
            >
              <PageTitles
                className={classNames(css.titleContainer, {
                  [css.isTeamTitle]: teamsInfo,
                  [css.hasDescription]: !!description,
                  [css.isDiscordWithBanner]: isDiscordWithBanner,
                })}
                title={title}
                subtitle={subtitle}
                description={description}
                affiliate={affiliate}
                genres={genres}
                isGame={!!teamsInfo}
                isDiscord={isDiscord}
              />
              <div
                className={classNames(css.actionButtonsContainer, {
                  [css.isTeamTitle]: teamsInfo,
                  [css.isDiscordWithBanner]: isDiscordWithBanner,
                })}
              >
                {canExpand &&
                  (isDiscordWithBanner || !isMediumBreakpoint) &&
                  renderCollapsibleLabel()}
                {!hidePlayButton && (
                  <div
                    className={classNames(css.profilePlayButton, {
                      [css.withoutFavorites]: !canFollow,
                      [css.withoutMoreInfo]: !canExpand,
                      [css.isDiscordWithBanner]: isDiscordWithBanner,
                      [css.isDisabled]:
                        isDiscord &&
                        (!discordState?.canControlPlayback ||
                          (nowPlayingRejectReasonKey &&
                            guideItem.guideId === tunedGuideId)),
                    })}
                  >
                    <PlayButton
                      guideItem={guideItem}
                      breakpoint={breakpoint}
                      bannerImage={bannerImage}
                    />
                  </div>
                )}
                {shouldShowSocialControls && (
                  <SocialControls
                    canFollow={canFollow}
                    hidePlayButton={hidePlayButton}
                    breakpoint={breakpoint}
                    guideItem={guideItem}
                    handleSharing={() => {
                      actions.sharingFromProfile(guideItem);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </section>
        {isDiscord && accordionSection}
      </div>
      {!isDiscord && accordionSection}
    </>
  );
}

Header.propTypes = {
  breakpoint: PropTypes.number.isRequired,
  routeProps: PropTypes.object.isRequired,
  canShowAds: PropTypes.bool.isRequired,
  // from mapDispatchToProps
  actions: PropTypes.object.isRequired,
  // from mapStateToProps
  guideItem: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  bannerImage: PropTypes.string,
  heroHeader: PropTypes.bool,
  image: PropTypes.string.isRequired,
  profileActions: PropTypes.object.isRequired,
  subtitle: PropTypes.string,
  teamsInfo: PropTypes.object,
  contentInfo: PropTypes.object,
  description: PropTypes.string,
  affiliate: PropTypes.object,
  genres: PropTypes.object,
  canExpand: PropTypes.bool,
  shouldShowMore: PropTypes.bool,
  handleMoreClick: PropTypes.func,
  isDiscord: PropTypes.bool.isRequired,
  discordState: PropTypes.object.isRequired,
};

Header.defaultProps = {
  profileActions: {},
  actions: {},
  image: '',
  title: '',
};

function mapStateToProps(state, ownProps) {
  const { selectedGuideId } = ownProps;
  const { profiles } = state;
  const profile = profiles?.[selectedGuideId] || {};
  const {
    actions,
    bannerImage,
    heroHeader,
    image,
    title,
    subtitle,
    properties,
    presentation,
  } = profile;

  const teamsInfo = properties?.teamsInfo;
  const contentInfo = properties?.contentInfo;
  const description =
    presentation?.expandableDescription?.canExpand &&
    properties?.description?.text;
  const affiliate = properties?.affiliate;
  const genres = properties?.genres;

  return {
    guideItem: profile,
    profileActions: actions,
    image,
    title,
    bannerImage,
    heroHeader,
    subtitle,
    description,
    affiliate,
    genres,
    teamsInfo,
    contentInfo,
    isDiscord: selectIsDiscord(state),
    discordState: selectDiscordState(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ sharingFromProfile }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
