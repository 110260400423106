import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { logInfo } from '../../../actions/logging';

/**
 * This component should be used to generate guide item links based on the passed in `guideItemLink`
 * function property, where we need to log situations where a link failed to be created (e.g. a
 * common situation deals with missing guide item link metadata, such as guideid or titie/seoname).
 */
class GuideItemLinkWithLogger extends Component {
  static propTypes = {
    /**
     * A guide item link function that should be an anonymous function returned for one of the
     * link builder functions found in `src/common/utils/guideItem/getGuideItemPathname`:
     * 1. getGuideItemPathnameWithLogger
     * 2. getDestInfoUrlObjWithLogger
     * 3. buildGuideItemPathnameWithLogger
     */
    guideItemLink: PropTypes.func.isRequired,

    /**
     * Redux action creator logger returned via the mapDispatchToProps below.
     */
    logInfo: PropTypes.func.isRequired,
  };

  // Setting this as state since this function is capable of side-effects, which are
  // required to happen either before render or after render.
  state = {
    to: this.props.guideItemLink(this.props.logInfo),
  };

  render() {
    /* eslint-disable no-unused-vars */
    /**
     * Extracting these out here so they aren't passed down as props to Link or div below.
     */
    const { guideItemLink, logInfo: propsLogInfo, ...otherProps } = this.props;
    /* eslint-enable no-unused-vars */

    const { to } = this.state;

    if (to) {
      return <Link to={to} {...otherProps} />;
    }

    return <div {...otherProps} />;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ logInfo }, dispatch);
}

export default connect(null, mapDispatchToProps)(GuideItemLinkWithLogger);
