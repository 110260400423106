import classNames from 'clsx';
import PropTypes from 'prop-types';
import PillButton from './PillButton';
import css from './pill-button.module.scss';

const OutlinedPillButton = ({ className, ...otherProps }) => (
  <PillButton
    className={classNames(css.outlinedButton, className)}
    {...otherProps}
  />
);

OutlinedPillButton.propTypes = {
  className: PropTypes.string,
};

export default OutlinedPillButton;
