import classNames from 'clsx';
import PropTypes from 'prop-types';
import { getDestInfoUrlObj } from 'src/common/utils/guideItem/getGuideItemPathname';
import ClampText from '../shared/ClampText';
import ChevronRightLink from '../shared/link/ChevronRightLink';
import css from './pageTitles.module.scss';

const PageTitles = ({
  className,
  title,
  subtitle,
  description,
  affiliate,
  genres,
  isGame,
  isDiscord,
}) => {
  const subtitleObject = !isGame && (affiliate || genres);
  const pageSubtitleClassNames = classNames(css.pageSubtitle, {
    [css.isDiscord]: isDiscord,
  });

  return (
    <div className={className}>
      <h1
        data-testid="profileTitle"
        className={classNames(css.pageTitle, {
          [css.isDiscord]: isDiscord,
        })}
        title={title}
      >
        {title}
      </h1>
      {subtitleObject ? (
        <ChevronRightLink
          data-testid="profileSubtitleLink"
          to={getDestInfoUrlObj(subtitleObject.destinationInfo)}
          chevronClassName={css.chevron}
          assetLoc="assets/img/shared/chevron-right-grey.svg"
          injectSvg
        >
          <h2 className={pageSubtitleClassNames} data-testid="profileSubtitle">
            {subtitleObject.displayName}
          </h2>
        </ChevronRightLink>
      ) : (
        <h2 className={pageSubtitleClassNames} data-testid="profileSubtitle">
          {subtitle}
        </h2>
      )}
      {description && (
        <h2 className={css.description} data-testid="profileTitleDescription">
          <ClampText clamp={4} className={css.description}>
            {description}
          </ClampText>
        </h2>
      )}
    </div>
  );
};

PageTitles.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  affiliate: PropTypes.object,
  genres: PropTypes.object,
  isGame: PropTypes.bool,
  isDiscord: PropTypes.bool,
};

export default PageTitles;
