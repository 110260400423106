import classNames from 'clsx';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectIsFord } from '../../selectors/app';
import assetUrl from '../../utils/assetUrl';
import { isDiscordMode } from '../../utils/discord';
import sharedCss from '../containerItems/shared/guide-item.module.scss';
import { DiscordGeoRestrictionsTileAreaTooltip } from '../discord/DiscordGeoRestrictionsTooltip';
import ImageWithDefault from '../shared/ImageWithDefault';
import css from './profile-logo.module.scss';

const ProfileLogo = ({
  image,
  title,
  hasPremiumBadge,
  hasBoostBadge,
  containerClassName,
  imageWrapperClassName,
  guideItem,
}) => {
  const isFord = useSelector(selectIsFord);

  const applyBadge = (url, alt) => (
    <img className={sharedCss.badgeImage} src={assetUrl(url)} alt={alt} />
  );

  return (
    <div className={classNames(containerClassName, css.logoContainer)}>
      {!isFord &&
        hasPremiumBadge &&
        applyBadge('assets/img/shared/premium-badge.svg')}
      {/* The Discord check can be removed when PLATFORM-17505 is resolved */}
      {!hasPremiumBadge &&
        hasBoostBadge &&
        !isDiscordMode() &&
        applyBadge('assets/img/shared/boost-badge.svg')}
      <ImageWithDefault
        src={image}
        alt={`${title}-logo`}
        dataTestId="logo"
        className={css.logoImage}
        containerClassName={classNames(imageWrapperClassName, css.logoWrapper)}
      />
      {isDiscordMode() && (
        <DiscordGeoRestrictionsTileAreaTooltip guideItem={guideItem} />
      )}
    </div>
  );
};

ProfileLogo.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  hasPremiumBadge: PropTypes.bool,
  hasBoostBadge: PropTypes.bool,
};

export default ProfileLogo;
