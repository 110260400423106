import get from 'lodash/get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Error404 from '../components/errors/404';
import Error500 from '../components/errors/500';

function Failed({ errorStatusCode }) {
  return (
    // platform 400 is treated as a conventional 404
    errorStatusCode === 400 || errorStatusCode === 404 ? (
      <Error404 />
    ) : (
      <Error500 />
    )
  );
}

Failed.propTypes = {
  errorStatusCode: PropTypes.number,
};

export default function connectWithFailureState(
  getAsyncResultState,
  FailedComponent = Failed,
) {
  return (BaseComponent) => {
    const WithFailureState = (props) =>
      props.hasFailed ? (
        <FailedComponent {...props} />
      ) : (
        <BaseComponent {...props} />
      );

    WithFailureState.propTypes = {
      hasFailed: PropTypes.bool,
    };

    function mapStateToProps(state, props) {
      const asyncResult = getAsyncResultState(state, props);

      return {
        hasFailed: get(asyncResult, 'hasFailed'),
        errorStatusCode: get(asyncResult, 'errorStatusCode'),
      };
    }

    return connect(mapStateToProps)(WithFailureState);
  };
}
