import isArray from 'lodash/isArray';

export default function isCBSAffiliated(guideItem) {
  const infoAttributes = guideItem.infoAttributes;
  if (!isArray(infoAttributes)) return false;

  const networks = infoAttributes.filter(
    (attr) => attr.name === 'Networks:' && isArray(attr.children),
  );
  if (networks.length !== 1) return false;

  const cbsNetworkTextRegex = /^CBS\sRadio/;
  return !!networks[0].children.filter((network) =>
    network.text.match(cbsNetworkTextRegex),
  ).length;
}

/*
Sample infoAttributes response for a CBS-affiliated guideItem

infoAttributes: [
  {
    "name":"Location:",
    "text":"Cleveland Heights, OH",
    "isPrimary":false
  },
  {
    "name":"Genres:",
    "isPrimary":false,
    "children":[
      {
        "text":"Sports Talk & News",
        "actions":{
          "browse":{
            "canBrowse":true,
            "guideId":"g2723",
            "destinationInfo":{
              "id":"g2723",
              "seoName":"Sports Talk & News",
              "requestType":"Browse"
            }
          }
        }
      }
    ]
  },
  {
    "name":"Networks:",
    "isPrimary":false,
    "children":[
      {
        "text":"CBS Radio",
        "actions":{
          "browse":{
            "canBrowse":true,
            "guideId":"a33083",
            "destinationInfo":{
              "id":"a33083",
              "seoName":"CBS Radio",
              "requestType":"Browse"
            }
          }
        }
      },
      {
        "text":"CBS News/Talk/Sports",
        "actions":{
          "browse":{
            "canBrowse":true,
            "guideId":"a38458",
            "destinationInfo":{
              "id":"a38458",
              "seoName":"CBS News/Talk/Sports",
              "requestType":"Browse"
            }
          }
        }
      },
      {
        "text":"Auburn Tigers (Football)",
        "actions":{
          "browse":{
            "canBrowse":true,
            "guideId":"a37493",
            "destinationInfo":{
              "id":"a37493",
              "seoName":"Auburn Tigers (Football)",
              "requestType":"Browse"
            }
          }
        }
      },
      {
        "text":"Cleveland Browns",
        "actions":{
          "browse":{
            "canBrowse":true,
            "guideId":"a37462",
            "destinationInfo":{
              "id":"a37462",
              "seoName":"Cleveland Browns",
              "requestType":"Browse"
            }
          }
        }
      }
    ]
  },
  {
    "name":"Description:",
    "text":"92.3 The Fan is a sports talk radio station based out of Cleveland, OH.",
    "isPrimary":false
  },
  {
    "name":"Twitter:",
    "isPrimary":false,
    "children":[
      {
        "text":"@923thefan",
        "actions":{
          "link":{
            "canLink":true,
            "webUrl":"http://twitter.com/923thefan"
          }
        }
      }
    ]
  },
  {
    "name":"Language:",
    "text":"English",
    "isPrimary":false
  },
  {
    "name":"Contact:",
    "text":"1041 Huron Road\r\nCleveland OH 44115",
    "isPrimary":false
  },
  {
    "name":"Website:",
    "isPrimary":false,
    "children":[
      {
        "text":"http://www.923thefan.com",
        "actions":{
          "link":{
            "canLink":true,
            "webUrl":"http://www.923thefan.com"
          }
        }
      }
    ]
  }
]
*/
