import assetUrl from 'src/common/utils/assetUrl';
import isServer from 'src/common/utils/isServer';

export default function handleAutoplayCheck(autoplay) {
  if (isServer()) {
    return Promise.resolve();
  }
  const audio = document.createElement('audio');
  audio.src = assetUrl('assets/media/blank.mp3');
  const promise = audio.play();

  if (promise && promise.then) {
    return promise.then(() => {
      autoplay();
    });
  }

  // [wells]: Default to autoplaying for browsers that do
  // not return a promise from .play() (Firefox, IE11 and Edge)
  autoplay();
  return Promise.resolve();
}
